@use '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.about-section {
    width: 100%;
    margin-bottom: 375px;
    @include mixins.flex(row, center, center);

    @media (min-width: 1680px) {
        margin-bottom: 432px;
    }

    @media (max-width: 1000px) {
        margin-bottom: 262px;
    }

    @media (max-width: 500px) {
        margin-bottom: 183px;
    }

    .about-section-container {
        width: calc(100% - 160px);
        max-width: 1280px;
        gap: 232px;
        @include mixins.flex(column, flex-start, center);

        @media (min-width: 1680px) {
            width: calc(100% - 200px);
            max-width: 1440px;
            gap: 156px;
        }

        @media (max-width: 1150px) {
            gap: 115px;
        }

        @media (max-width: 1000px) {
            width: calc(100% - 80px);
        }

        @media (max-width: 730px) {
            gap: 72px;
        }

        @media (max-width: 500px) {
            width: calc(100% - 32px);
        }

        .about-section-biography {
            width: calc(100% - 90px);
            margin-left: 90px;
            height: 617px;
            position: relative;
            color: $white;
            @include mixins.flex(column, space-between, flex-start);

            @media (min-width: 1680px) {
                width: 100%;
                height: 800px;
                margin-left: 0;
            }

            @media (max-width: 1260px) {
                width: 100%;
                margin-left: 0;
            }

            @media (max-width: 1150px) {
                height: 655px;
                gap: 102px;
                @include mixins.flex(column, flex-start, flex-start);
            }

            @media (max-width: 730px) {
                height: unset;
                gap: 390px;
            }

            .biography-title{
                mix-blend-mode: difference;
                z-index: 3;
                gap: 16px;
                @include mixins.flex(column, flex-start, flex-start);

                @media (max-width: 730px) {
                    width: 100%;
                }

                h1 {
                    width: 496px;
                    text-align: end;
                    text-transform: uppercase;
                    @include mixins.font(Cinzel, 88px, normal, 500, 110%);

                    @media (min-width: 1680px) {
                        width: 626px;
                        @include mixins.font(Cinzel, 112px, normal, 520, 100%);
                    }

                    @media (max-width: 1000px) {
                        margin-left: -21px;
                    }

                    @media (max-width: 800px) {
                        width: 288px;
                        @include mixins.font(Cinzel, 50px, normal, 520, 110%);
                    }

                    @media (max-width: 730px) {
                        width: 100%;
                        text-align: center;
                        margin-left: 0;
                    }

                    @media (max-width: 500px) {
                        @include mixins.font(Cinzel, 40px, normal, 520, 110%);
                    }

                    @media (max-width: 370px) {
                        @include mixins.font(Cinzel, 32px, normal, 520, 110%);
                    }
                }

                h2 {
                    margin-left: 24px;
                    text-transform: uppercase;
                    @include mixins.font(Cinzel, 20px, normal, 500, 110%);

                    @media (min-width: 1680px) {
                        @include mixins.font(Cinzel, 28px, normal, 500, 140%);
                    }

                    @media (max-width: 1000px) {
                        margin-left: 0;
                    }

                    @media (max-width: 800px) {
                        width: 250px;
                        @include mixins.font(Cinzel, 20px, normal, 500, 140%);
                    }

                    @media (max-width: 730px) {
                        width: 143px;
                        text-align: end;
                        position: absolute;
                        top: 95px;
                        right: 0;
                        @include mixins.font(Cinzel, 14px, normal, 520, 130%);
                    }

                    @media (max-width: 500px) {
                        top: 60px;
                    }

                }
            }

            .biography-text {
                width: 318px;
                margin-left: 24px;
                z-index: 3;
                gap: 16px;
                mix-blend-mode: difference;
                @include mixins.flex(column, flex-start, flex-start);
                @include mixins.font(Lora, 16px, normal, 400, 150%);

                @media (min-width: 1680px) {
                    width: 402px;
                    gap: 20px;
                    @include mixins.font(Lora, 20px, normal, 400, 150%);
                }

                @media (max-width: 1000px) {
                    margin-left: 0;
                }

                @media (max-width: 800px) {
                    width: 260px;
                }

                @media (max-width: 730px) {
                    width: 100%;
                    @include mixins.font(Lora, 20px, normal, 400, 150%);
                }

                @media (max-width: 500px) {
                    @include mixins.font(Lora, 16px, normal, 400, 150%);
                }
            }

            .about-section-image {
                width: 420px;
                height: 568px;
                position: absolute;
                left: 400px;
                bottom: 0;

                @media (min-width: 1680px) {
                    width: 555px;
                    height: 750px;
                    left: 530px;
                }

                @media (max-width: 1150px) {
                    left: unset;
                    right: 0;
                    bottom: 98px;
                }

                @media (max-width: 1150px) {
                    width: 367px;
                    height: 496px;
                }

                @media (max-width: 730px) {
                    width: 163px;
                    height: 221px;
                    bottom: unset;
                    top: 85px;
                    right: 50%;
                    transform: translateX(50%);
                }

                @media (max-width: 500px) {
                    top: 52px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            span {
                width: 325px;
                position: absolute;
                z-index: 3;
                right: 0;
                top: 107px;
                mix-blend-mode: difference;
                @include mixins.font(Lora, 30px, italic, 400, 140%);

                @media (min-width: 1680px) {
                    width: 303px;
                    top: 136px;
                    @include mixins.font(Lora, 32px, italic, 400, 140%);
                }

                @media (max-width: 1150px) {
                    width: 311px;
                    top: unset;
                    bottom: 0;
                    margin-right: 56px;
                    @include mixins.font(Lora, 24px, italic, 500, 140%);
                }

                @media (max-width: 730px) {
                    bottom: unset;
                    top: 335px;
                    left: 0;
                    right: unset;
                    margin-right: 0px;
                }

                @media (max-width: 500px) {
                    width: 298px;
                    top: 290px;
                    @include mixins.font(Lora, 23px, italic, 400, 140%);
                }
            }
        }

        .about-section-quote {
            width: calc(100% - 388px);
            margin: 0 194px;
            padding: 64px 0;
            position: relative;
            color: $black;
            border-top: 1px solid $line-color;
            border-bottom: 1px solid $line-color;
            @include mixins.flex(column, center, center);

            @media (min-width: 1680px) {
                width: 100%;
                margin: 0 0;
                padding: 72px 0;
            }

            @media (max-width: 1260px) {
                width: 100%;
                margin: 0 0;
            }

            @media (max-width: 800px) {
                padding: 40px 0;
            }

            &:before {
                content: url('../../assets/quotes.svg');
                padding: 0 16px;
                background-color: $main-color;
                position: absolute;
                top: -11px;
            }

            p {
                width: 682px;
                text-transform: uppercase;
                @include mixins.font(Cinzel, 28px, normal, 500, 140%);

                @media (min-width: 1680px) {
                    width: 879px;
                    @include mixins.font(Cinzel, 36px, normal, 500, 140%);
                }

                @media (max-width: 800px) {
                    width: 100%;
                    text-align: center;
                }

                @media (max-width: 730px) {
                    @include mixins.font(Cinzel, 14px, normal, 500, 150%);
                }
            }

            span {
                position: absolute;
                bottom: -56px;
                @include mixins.font(Lora, 18px, italic, 500, 120%);

                @media (min-width: 1680px) {
                    @include mixins.font(Lora, 20px, italic, 500, 120%);
                }

                @media (max-width: 730px) {
                    bottom: -54px;
                    @include mixins.font(Lora, 12px, italic, 400, 120%);
                }
            }
        }
    }
}