@use '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.carousel {
    width: 487px;
    height: 605px;
    position: relative;
    overflow: hidden;

    @media (min-width: 1680px) {
        width: 658px;
        height: 817px;
    }

    @media (max-width: 600px) {
        width: 256px;
        height: 384px;
    }

    .carousel-container {
        width: 100%;
        height: 100%;
        position: relative;
        top: -100%;
        z-index: 3;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            z-index: 1;

            @media (max-width: 600px) {
                height: 100%;
                object-fit: cover;
            }

            &.move {
                // transform: translateY(100%);
                z-index: 3;
                animation: 3s carousel forwards ease-in-out;
            }
        }

        .carousel-image {
            z-index: 1;
        }
    }

    .carousel-main-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
        transform: translateY(-100%);
        z-index: 2;

        @media (max-width: 600px) {
            height: 100%;
            object-fit: cover;
        }
    }
}

// Carousel Animation

@keyframes carousel {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
}