@use '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.form-block {
    width: 100%;
    height: 100%;
    position: relative;
    @include mixins.flex(column, flex-start, flex-start);

    .form-block-text {
        width: 100%;
        margin-bottom: 40px;
        @include mixins.font(Lora, 30px, italic, 400, 140%);

        @media (min-width: 1680px) {
            @include mixins.font(Lora, 32px, italic, 400, 140%);
        }

        @media (max-width: 1050px) {
            @include mixins.font(Lora, 24px, italic, 500, 140%);
        }

        @media (max-width: 500px) {
            margin-bottom: 24px;
            @include mixins.font(Lora, 16px, italic, 400, 130%);
        }
    }

    .form-block-warning-text {
        width: 173px;
        color: $grey;
        position: absolute;
        bottom: 0;
        left: 0;
        @include mixins.font(Lora, 14px, italic, 400, 140%);

        @media (max-width: 500px) {
            display: none;
        }
    }

    form {
        width: 100%;

        .input-field {
            width: 100%;
            padding-bottom: 20px;
            position: relative;

            &::before {
                content: '';
                width: 0px;
                height: 1px;
                background-color: $black;
                position: absolute;
                bottom: 20px;
                right: 0px;
                transition: all 0.5s ease-in-out;
                z-index: 5;

                @media (max-width: 720px) {
                    display: none;
                }
            }

            &.error {
                &::before {
                    z-index: -1;
                }
            }

            &.textarea {
                padding-top: 20px;

                &::before {
                    bottom: 22.5px;
                }

                .message {
                    bottom: unset;
                    top: 0px !important;
                    @include mixins.font(Lora, 14px, italic, 500, 120%);
                }

                .fullMessage {
                    bottom: unset;
                    top: 0px !important;
                    @include mixins.font(Lora, 14px, italic, 500, 120%);
                }

                .textarea {
                    height: 30px;
                    resize: none;

                    &:focus {
                        height: 75px;
                    }
                }
            }

            @media (max-width: 500px) {
                padding-bottom: 18px;
            }

            .input-item {
                // color: $transparent;
                width: 100%;
                height: 50px;
                background-color: $transparent;
                border-top: none;
                border-left: none;
                border-right: none;
                border-bottom: 1px solid $line-color;
                outline: none;
                position: relative;
                transition: all 0.3s ease-in-out;
                @include mixins.font(Lora, 14px, italic, 500, 120%);

                // @media (min-width: 1680px) {
                //     @include mixins.font(Lora, 20px, italic, 500, 120%);
                // }

                @media (max-width: 500px) {
                    height: 41px;
                    @include mixins.font(Lora, 16px, italic, 400, 130%);
                }

                &.error {
                    border-bottom: 1px solid $red;
                    @include mixins.font(Lora, 14px, italic, 500, 120%);

                    &:focus {
                        border-bottom: 1px solid $red;
                        @include mixins.font(Lora, 14px, italic, 500, 120%);
                    }
                }

                &::placeholder {
                    color: $line-color;
                    opacity: 0;
                    transition: all 0.5s ease-in-out;
                    @include mixins.font(Lora, 14px, italic, 500, 120%);

                    // @media (min-width: 1680px) {
                    //     @include mixins.font(Lora, 20px, italic, 500, 120%);
                    // }

                    // @media (max-width: 500px) {
                    //     @include mixins.font(Lora, 16px, italic, 400, 130%);
                    // }
                }

                &:focus::placeholder {
                    opacity: 1;
                }

                &:focus {
                    border-bottom: 1px solid $black;
                }
            }

            

            .err-txt {
                position: absolute;
                color: $red;
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
                @include mixins.font(Lora, 14px, italic, 400, 150%);

                @media (max-width: 500px) {
                    @include mixins.font(Lora, 10px, italic, 400, 150%);
                }

                &.error {
                    opacity: 1;
                }
            }

            .err-icon {
                position: absolute;
                right: 0;
                bottom: 33px;
                opacity: 0;
                transition: opacity 0.3s ease-in-out;

                @media (max-width: 500px) {
                    width: 15px;
                    bottom: 26px;
                }

                &.error {
                    opacity: 1;
                }
            }

            .date-icon {
                position: absolute;
                right: 0;
                bottom: 33px;
                opacity: 1;
                transition: opacity 0.2s ease-in-out;
                pointer-events: none;

                @media (max-width: 500px) {
                    width: 15px;
                    bottom: 26px;
                }

                &.error {
                    opacity: 0;
                }
            }

            .input-label {
                position: absolute;
                left: 0;
                bottom: 33px;
                pointer-events: none;
                transition: all 0.5s ease-in-out;
                @include mixins.font(Lora, 18px, italic, 500, 120%);

                @media (min-width: 1680px) {
                    @include mixins.font(Lora, 20px, italic, 500, 120%);
                }

                @media (max-width: 500px) {
                    bottom: 25px;
                    @include mixins.font(Lora, 16px, italic, 400, 130%);
                }

                &.textarea {
                    bottom: unset;
                    top: 15px;
                }

                &.name,
                &.email,
                // &.message,
                &.city,
                &.date {
                    bottom: 54px;
                    @include mixins.font(Lora, 14px, italic, 500, 120%);

                    @media (max-width: 500px) {
                        bottom: 47px;
                    }
                }

                &.error {
                    color: $red;
                }
            }

            .input-label-phone {
                min-width: 100px;
                background-color: $main-color;
                position: absolute;
                left: 0;
                bottom: 35px;
                transition: all 0.5s ease-in-out;
                @include mixins.font(Lora, 18px, italic, 500, 120%);

                @media (min-width: 1680px) {
                    @include mixins.font(Lora, 20px, italic, 500, 120%);
                }

                @media (max-width: 500px) {
                    bottom: 25px;
                    @include mixins.font(Lora, 16px, italic, 400, 130%);
                }

                &.focus {
                    background-color: $transparent;
                    bottom: 58px;
                    @include mixins.font(Lora, 14px, italic, 500, 120%);

                    @media (max-width: 500px) {
                        bottom: 47px;
                    }
                }

                &.error {
                    color: $red;
                    // @include mixins.font(Lora, 14px, italic, 500, 120%);
                }
            }
        }

        .input-field:hover::before {
            right: unset;
            left: 0px;
            width: 100%;
        }

        button {
            width: 196px;
            height: 47px;
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: $transparent;
            color: $black;
            border-radius: 12px 0px;
            border: 1px solid $black;
            text-transform: uppercase;
            transition: all 0.3s ease-in-out;
            cursor: pointer;
            @include mixins.font(Cinzel, 16px, normal, 561, 120%);

            @media (min-width: 1680px) {
                width: 214px;
                height: 49px;
                @include mixins.font(Cinzel, 18px, normal, 561, 120%);
            }

            @media (max-width: 500px) {
                position: static;
                width: 100%;
            }

            &:hover {
                background-color: $black;
                color: $main-color;
            }
        }
    }
}

// Date Input Styles 

input[type=date]:required:invalid::-webkit-datetime-edit {
    color: $transparent;
    pointer-events: none;
    transition: all 0.5s ease-in-out;
}

input[type=date]:focus::-webkit-datetime-edit {
    color: $line-color !important;
    pointer-events: none;
    transition: all 0.5s ease-in-out;
}

input[type=date]:focus::-webkit-datetime-edit-year-field:not([aria-valuenow]),
input[type=date]:focus::-webkit-datetime-edit-month-field:not([aria-valuenow]),
input[type=date]:focus::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
    color: $line-color !important;
    pointer-events: none;
    transition: all 0.5s ease-in-out;
}

::-webkit-calendar-picker-indicator {
    
}


// Phone Input Styling

.react-tel-input {

    .special-label {
        display: none;
    }

    .phone {
        // width: 90% !important;
        // height: 18px !important;
        // border: none !important;
        // padding-left: 200px;
        // background: transparent !important;
        // color: #0e5a18;
        // font-size: 20px !important;
        // @include mixins.font(Forum, 20px, normal, 600, 110%);
        padding-left: 40px;
    }

    .flag-dropdown {
        height: 20px !important;
        top: 50% !important;
        left: 0% !important;
        transform: translateY(-50%);
        border: none !important;
        background-color: transparent !important;
        position: absolute !important;
        

        &.open {
            background-color: transparent !important;
        }

        .selected-flag {
            width: 30px !important;
            height: 20px !important;
            padding: 0 !important;
            background-color: transparent !important;

            &.open {
                background-color: transparent !important;
            }
        }

        .country-list {
            margin: 17px 0 10px -1px;
            background-color: $beige;
            color: $brown;

            &::-webkit-scrollbar {
                width: 10px ;
            }

            &::-webkit-scrollbar-track {
                background: $brown;
            }

            &::-webkit-scrollbar-thumb {
                background: $beige;
                border: 2px solid $brown;
            }

            li {
                border-bottom: 1px solid $beige-stroke;

                &.highlight {
                    background-color: $brown;
                    color: $beige !important;

                    .dial-code {
                        color: $beige !important;
                    }
                }
            }

            .dial-code {
                color: $brown !important;
            }
        }
    }
}