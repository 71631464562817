// Colors

$main-color: #FEFCF9;
$white: #fff;
$black: #1E1D1C;
$grey: #757473;
$red: #AE2323;
$beige: #E8E3D6;
$brown: #4B4639;
$beige-stroke: #DED8C9;
$line-color: #C8C7C6;
$transparent: transparent;