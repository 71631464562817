@use '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.footer {
    width: 100%;
    height: 744px;
    padding-bottom: 32px;
    padding-top: 460px;
    color: $brown;
    position: relative;
    @include mixins.flex(row, center, center);

    @media (min-width: 1680px) {
        height: 870px;
        padding-bottom: 40px;
        padding-top: 620px;
    }

    @media (max-width: 1250px) {
        padding-bottom: 40px;
    }

    @media (max-width: 500px) {
        height: 735px;
        padding-bottom: 32px;
        padding-top: 220px;
    }

    .footer-background {
        width: 100%;
        height: 744px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-color: $beige;

        @media (min-width: 1680px) {
            height: 870px;
        }

        @media (max-width: 500px) {
            height: 765px;
        }
    }

    .footer-container {
        width: calc(100% - 160px);
        height: 100%;
        @include mixins.flex(column, flex-end, center);

        @media (min-width: 1680px) {
            width: calc(100% - 200px);
            max-width: 1720px;
        }

        @media (max-width: 1000px) {
            width: calc(100% - 80px);
        }

        @media (max-width: 500px) {
            width: calc(100% - 32px);
        }


        .footer-carrousel {
            width: 100%;
            height: 357px;
            position: absolute;
            left: 0;
            top: 280px;

            @media (min-width: 1680px) {
                height: 500px;
                top: 380px;
            }

            @media (max-width: 1250px) {
                height: 384px;
                top: 310px;
            }

            @media (max-width: 500px) {
                height: 251px;
                top: 128px;
            }

            .footer-carrousel-container {
                width: 100%;
                position: absolute;
                overflow: hidden;
                @include mixins.flex(row, flex-start, flex-start);
            }
        }

        .footer-carrousel-button-container {
            width: 100%;
            height: 700px;
            position: absolute;
            top: 110px;

            @media (min-width: 1680px) {
                height: 1000px;
            }

            @media (max-width: 1250px) {
                display: none;
            }

            .footer-carrousel-button-container-wrapper {
                width: 100%;
                height: 100%;
                position: relative;

                .footer-carrousel-button-border {
                    width: 100%;
                    height: 24%;
                    position: absolute;
                    z-index: 20;

                    &.top {
                        top: 0;
                    }

                    &.bottom {
                        bottom: 0;
                    }
    
                }

                .footer-carrousel-button-container-inner {
                    width: 100%;
                    height: 51%;
                    position: absolute;
                    top: 260px;
    
                    // &:hover .footer-carrousel-button-inner {
                    //     scale: 1 !important;
                    // }
    
                    .footer-carrousel-button {
                        width: 180px;
                        height: 180px;
                        border-radius: 1000px;
                        cursor: pointer;
                        
    
                        .footer-carrousel-button-inner {
                            width: 180px;
                            height: 180px;
                            position: absolute;
                            border-radius: 1000px;
                            border: 3px solid $beige-stroke;
                            background: $beige;
                            text-transform: uppercase;
                            scale: 0;
                            transition: scale 0.3s ease-in-out;
                            z-index: 10;
                            @include mixins.flex(row, center, center);
                            @include mixins.font(Cinzel, 16px, normal, 561, 120%);
                        }
                    }
                }
        }
    }

        .footer-info-main {
            width: 100%;
            @include mixins.flex(row, space-between, flex-start);

            @media (max-width: 800px) {
                @include mixins.flex(column, flex-end, center);
            }

            div {
                width: 335px;
                gap: 24px;
                @include mixins.flex(column, flex-start, flex-start);

                @media (min-width: 1680px) {
                    width: 405px;
                }

                @media (max-width: 1250px) {
                    width: 234px;
                    gap: 20px;
                }

                @media (max-width: 800px) {
                    width: 100%;
                    margin-top: 64px;
                    order: 2;
                    @include mixins.flex(column, center, center);
                }

                h4 {
                    text-transform: uppercase;
                    @include mixins.font(Cinzel, 38px, normal, 561, 110%);

                    @media (min-width: 1680px) {
                        @include mixins.font(Cinzel, 48px, normal, 561, 100%);
                    }

                    @media (max-width: 1250px) {
                        @include mixins.font(Cinzel, 28px, normal, 561, 100%);
                    }

                    @media (max-width: 800px) {
                        display: none;
                    }
                }

                p {
                    width: 323px;
                    @include mixins.font(Lora, 16px, normal, 400, 150%);

                    @media (min-width: 1680px) {
                        width: 401px;
                        @include mixins.font(Lora, 20px, normal, 400, 150%);
                    }

                    @media (max-width: 1250px) {
                        width: 234px;
                        @include mixins.font(Lora, 16px, normal, 400, 150%);
                    }

                    @media (max-width: 800px) {
                        width: 80%;
                        text-align: center;
                    }

                    @media (max-width: 500px) {
                        width: 100%;
                    }
                }
            }

            ul {
                list-style: none;
                gap: 22px;
                @include mixins.flex(column, flex-start, flex-start);

                @media (max-width: 800px) {
                    margin-bottom: 22px;
                    @include mixins.flex(column, flex-start, center);
                }

                li {
                    overflow: hidden;
                    position: relative;
                    cursor: pointer;

                    &::before {
                        content: '';
                        width: 0px;
                        height: 1px;
                        background-color: $brown;
                        position: absolute;
                        bottom: 0px;
                        right: 0px;
                        transition: all 0.3s ease-in-out;
                    }
                }

                li:hover::before {
                    right: unset;
                    left: 0px;
                    width: 100%;
                }
            }

            span {
                text-transform: uppercase;
                @include mixins.font(Cinzel, 38px, normal, 561, 100%);

                @media (min-width: 1680px) {
                    @include mixins.font(Cinzel, 48px, normal, 561, 100%);
                }

                @media (max-width: 1250px) {
                    @include mixins.font(Cinzel, 28px, normal, 561, 100%);
                }

                @media (max-width: 500px) {
                    @include mixins.font(Cinzel, 22px, normal, 584, 100%);
                }
            }

            .footer-phone {
                overflow: hidden;
                position: relative;
                cursor: pointer;

                    &::before {
                        content: '';
                        width: 0px;
                        height: 1px;
                        background-color: $brown;
                        position: absolute;
                        bottom: 0px;
                        right: 0px;
                        transition: all 0.3s ease-in-out;
                    }

                @media (max-width: 800px) {
                    margin-top: 22px;
                }
            }

            .footer-phone:hover::before {
                right: unset;
                left: 0px;
                width: 100%;
            }
        }

        .footer-info-line {
            width: 100%;
            height: 1px;
            margin-top: 56px;
            background-color: $brown;

            @media (max-width: 500px) {
                margin-top: 32px;
            }
        }

        .footer-info-bottom {
            width: 100%;
            margin-top: 32px;
            position: relative;
            @include mixins.flex(row, space-between, center);

            @media (min-width: 1680px) {
                margin-top: 40px;
            }

            @media (max-width: 1250px) {
                gap: 8px;
                margin-top: 28px;
                @include mixins.flex(column, flex-start, flex-start);
            }

            @media (max-width: 600px) {
                @include mixins.flex(column, flex-start, center);
            }

            .footer-all-rights {
                @include mixins.font(Lora, 18px, italic, 500, 120%);

                @media (min-width: 1680px) {
                    @include mixins.font(Lora, 20px, italic, 500, 120%);
                }

                @media (max-width: 500px) {
                    @include mixins.font(Lora, 16px, italic, 400, 130%);
                }
            }

            .footer-dev-link {
                width: 223px;
                position: relative;
                @include mixins.font(Lora, 18px, italic, 500, 120%);

                @media (min-width: 1680px) {
                    width: 244px;
                    @include mixins.font(Lora, 20px, italic, 500, 120%);
                }

                @media (max-width: 500px) {
                    width: unset;
                    @include mixins.font(Lora, 16px, italic, 400, 130%);
                }

                &::before {
                    content: '';
                    width: 0px;
                    height: 1px;
                    background-color: $brown;
                    position: absolute;
                    bottom: 0px;
                    right: 0px;
                    transition: all 0.3s ease-in-out;
                }
            }

            .footer-dev-link:hover::before {
                right: unset;
                left: 0px;
                width: 100%;
            }

            .footer-button-up {
                width: 330px;
                text-transform: uppercase;
                @include mixins.font(Cinzel, 16px, normal, 561, 100%);
                @include mixins.flex(row, flex-end, center);

                @media (min-width: 1680px) {
                    width: 417px;
                    @include mixins.font(Cinzel, 18px, normal, 561, 100%);
                }

                @media (max-width: 1250px) {
                    position: absolute;
                    top: 0;
                    right: 0;
                }

                @media (max-width: 600px) {
                    width: unset;
                    position: static;
                    order: -1;
                    margin-bottom: 5px;
                }

                div {
                    gap: 8px;
                    position: relative;
                    cursor: pointer;
                    @include mixins.flex(row, flex-end, center);

                    &::before {
                        content: '';
                        width: 0px;
                        height: 1px;
                        background-color: $brown;
                        position: absolute;
                        bottom: 0px;
                        right: 0px;
                        transition: all 0.3s ease-in-out;
                    }
                }

                img {
                    margin-bottom: 4px;
                }


                div:hover::before {
                    right: unset;
                    left: 0px;
                    width: 100%;
                }
            }
        }
    }
}