@use '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.portfolio-bottom-nav {
    // width: 542px;
    height: 62px;
    padding: 0 32px;
    background-color: $beige;
    color: $grey;
    border-radius: 12px 0px;
    border: 3px solid $beige-stroke;
    transform: translateX(-50%);
    position: fixed;
    z-index: 99;
    bottom: -100%;
    left: 50%;
    text-transform: uppercase;
    transition: bottom 0.7s cubic-bezier(0.4, 0, 0.2, 1);
    gap: 40px;
    @include mixins.flex(row, space-around, center);
    @include mixins.font(Cinzel, 20px, normal, 500, 110%);

    @media (min-width: 1680px) {
        // width: 684px;
        height: 68px;
        @include mixins.font(Cinzel, 28px, normal, 500, 140%);
    }

    @media (max-width: 600px) {
        width: calc(100% - 80px - 48px);
        padding: 0 24px;
        margin: 0 40px;
        transform: translateX(calc(-50% - 40px));
        gap: 32px;
        @include mixins.flex(row, space-between, center);
    }

    @media (max-width: 500px) {
        width: calc(100% - 32px - 48px);
        height: 57px;
        margin: 0 16px;
        transform: translateX(calc(-50% - 16px));
        gap: unset;
        @include mixins.font(Cinzel, 18px, normal, 500, 110%);
    }

    @media (max-width: 360px) {
        @include mixins.font(Cinzel, 16px, normal, 500, 110%);
    }

    &.visible {
        bottom: 24px;
    }

    .nav-item {
        overflow: hidden;
        position: relative;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        // @media (min-width: 1680px) {
        //     @include mixins.font(Cinzel, 64px, normal, 500, 140%);
        // }

        // @media (max-width: 800px) {
        //     @include mixins.font(Cinzel, 40px, normal, 520, 110%);
        // }

        &:hover {
            color: $black;
        }

        &.active {
            color: $black;
            overflow: visible;

            &::after {
                content: url(../../assets/star-icon-2.svg);
                position: absolute;
                left: -14px;
                top: 50%;
                transform: translateY(-55%);
            }
        }

        &::before {
            content: '';
            width: 0px;
            height: 1px;
            background-color: $black;
            position: absolute;
            bottom: 0px;
            right: 0px;
            transition: all 0.3s ease-in-out;

            @media (max-width: 720px) {
                display: none;
            }
        }
    }

    // .nav-item:hover::before {
    //     right: unset;
    //     left: 0px;
    //     width: 100%;
    // }
}