@use '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.welcome-section {
    width: 100%;
    height: calc(100dvh - 110px);
    margin-bottom: 180px;
    background-color: $main-color;
    @include mixins.flex(row, center, center);

    @media (min-width: 1680px) {
        margin-bottom: 232px;
    }

    @media (max-width: 1000px) {
        margin-bottom: 144px;
    }

    @media (max-width: 500px) {
        height: calc(100dvh - 60px);
        margin-bottom: 104px;
    }

    .welcome-section-container {
        width: 100%;
        height: 100%;
        max-width: 1440px;
        position: relative; 

        @media (min-width: 1680px) {
            max-width: 1330px;
        }

        @media (max-width: 1160px) {
            width: calc(100% - 160px);
        }

        @media (max-width: 1000px) {
            width: calc(100% - 80px);
        }

        @media (max-width: 500px) {
            width: calc(100% - 32px);
        }
        
        .welcome-section-slider {
            position: absolute;
            top: 20px;
            right: 30%;

            @media (min-width: 1680px) {
                top: 30px;
                right: 260px;
            }

            @media (max-width: 1160px) {
                right: 50%;
                top: 5%;
                transform: translateX(50%);
            }

            @media (max-width: 600px) {
                top: 15%;
            }
        }

        .welcome-section-text {
            position: absolute;
            z-index: 10;
            mix-blend-mode: difference;
            color: $white;
            

            &.first,
            &.second,
            &.third {
                text-transform: uppercase;
                @include mixins.font(Cinzel, 120px, normal, 451, 100%);

                @media (min-width: 1680px) {
                    @include mixins.font(Cinzel, 140px, normal, 483, 100%);
                }

                @media (max-width: 1000px) {
                    @include mixins.font(Cinzel, 98px, normal, 451, 100%);
                }

                @media (max-width: 600px) {
                    @include mixins.font(Cinzel, 64px, normal, 451, 100%);
                }

                @media (max-width: 374px) {
                    @include mixins.font(Cinzel, 54px, normal, 451, 100%);
                }
            }

            &.first {
                top: 116px;
                left: 11%;

                @media (min-width: 1680px) {
                    top: 208px;
                    left: 0;
                }

                @media (max-width: 1160px) {
                    left: 0;
                }

                @media (max-width: 1000px) {
                    top: 155px;
                }

                @media (max-width: 835px) {
                    top: 8%;
                }
            }

            &.second {
                top: 195px;
                right: 11%; 

                @media (min-width: 1680px) {
                    top: 284px;
                    right: 0;
                }

                @media (max-width: 1160px) {
                    right: 0;
                }

                @media (max-width: 1000px) {
                    top: 242px;
                }

                @media (max-width: 835px) {
                    top: 20%;
                }

                @media (max-width: 374px) {
                    top: 17%;
                }
            }

            &.third {
                top: 325px;
                left: 15%;

                @media (min-width: 1680px) {
                    top: 444px;
                    left: 69px;
                }

                @media (max-width: 1160px) {
                    left: 0;
                }

                @media (max-width: 1000px) {
                    top: 356px;
                }

                @media (max-width: 835px) {
                    top: unset;
                    bottom: 18%;
                }
            }

            &.fourth {
                top: 253px;
                left: 16%;
                width: 224px;
                @include mixins.font(Lora, 18px, italic, 500, 120%);

                @media (min-width: 1680px) {
                    top: 372px;
                    left: 87px;
                    width: 262px;
                    @include mixins.font(Lora, 20px, italic, 500, 120%);
                }

                @media (max-width: 1160px) {
                    left: 1%;
                }

                @media (max-width: 1000px) {
                    top: 269px;
                }

                @media (max-width: 835px) {
                    top: unset;
                    bottom: 5%;
                    left: 50%;
                    transform: translateX(-50%);
                }

                @media (max-width: 600px) {
                    width: 197px;
                    @include mixins.font(Lora, 16px, italic, 400, 120%);
                }
            }

            &.fifth {
                top: 150px;
                right: 18%;
                width: 134px;
                text-transform: uppercase;
                @include mixins.font(Cinzel, 16px, normal, 561, 120%);

                @media (min-width: 1680px) {
                    top: 217px;
                    right: 40px;
                    width: 176px;
                    @include mixins.font(Cinzel, 18px, normal, 561, 120%);
                }

                @media (max-width: 1160px) {
                    right: 20px;
                }

                @media (max-width: 1000px) {
                    top: 189px;
                }

                @media (max-width: 835px) {
                    width: 100%;
                    top: 2%;
                    right: unset;
                    left: 0;
                }

                @media (max-width: 600px) {
                    @include mixins.font(Cinzel, 14px, normal, 520, 130%);
                }
            }
        }

        .welcome-portfolio-pointer {
            position: absolute;
            bottom: 20px;
            left: 11%;
            text-transform: uppercase;
            gap: 4px;
            @include mixins.flex(row, center, center);
            @include mixins.font(Cinzel, 16px, normal, 561, 120%);

            @media (min-width: 1680px) {
                bottom: 40px;
                left: 0;
                @include mixins.font(Cinzel, 18px, normal, 561, 120%);
            }

            @media (max-width: 1160px) {
                left: 0;
            }

            @media (max-width: 1000px) {
                display: none;
            }

            img {
                margin-bottom: 3px;
            }
        }
    }
}

