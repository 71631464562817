@use '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.loading-page {
    width: 100%;
    height: 100vh;
    background-color: $main-color;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    @include mixins.flex(row, center, center);

    .loading-logo {
        width: 49px;
        position: absolute;
        top: 24px;
        left: 50%;
        transform: translateX(-50%);
        // animation: logoAnimation 1.5s infinite;

        img {
            width: 100%;
        }
    }

    .loading-progress-bar {
        width: calc(100% - 80px);
        margin-right: 80px;
        color: $white;
        mix-blend-mode: difference;
        position: relative;
        z-index: 5;

        @media (min-width: 1680px) {
            width: calc(100% - 100px);
            margin-right: 100px;
        }

        @media (max-width: 1000px) {
            width: calc(100% - 40px);
            margin-right: 40px;
        }

        @media (max-width: 500px) {
            width: calc(100% - 16px);
            margin-right: 16px;
        }


        .loading-progress-percentage {
            text-transform: uppercase;
            text-align: end;
            transition: margin-left 0.5;
            @include mixins.font(Cinzel, 28px, normal, 500, 140%);
        }

        .loading-progress-line {
            height: 1px;
            background-color: $white;
            transition: width 0.5;
        }
    }

    .loading-image {
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
    }
}

@keyframes logoPageHide {
    0% { opacity: 1; }
    100% { 
        opacity: 0;
        pointer-events: none;
        z-index: -2;
    }
}

@keyframes logoAnimation {
    0% { opacity: 1; }
    50% { opacity: 0.3; }
    100% { opacity: 1; }
}