@use '../../styles/mixins.scss';
@import '../../styles/variables.scss';


.header {
    width: 100%;
    height: 110px;
    position: fixed;
    mix-blend-mode: difference;
    top: -1px;
    z-index: 1000;
    transition: top 0.7s cubic-bezier(0.4, 0, 0.2, 1);
    @include mixins.flex(row, center, center);

    @media (max-width: 500px) {
        height: 60px;
    }

    &.hide {
        top: -100%;
    }

    &.open {
        mix-blend-mode: normal;
    }

    .header-container {
        width: calc(100% - 160px);
        height: 100%;
        @include mixins.flex(row, space-between, center);

        @media (min-width: 1680px) {
            width: calc(100% - 200px);
        }

        @media (max-width: 1000px) {
            width: calc(100% - 80px);
        }

        @media (max-width: 500px) {
            width: calc(100% - 32px);
        }

        .header-logo {
            width: 49px;

            @media (max-width: 500px) {
                width: 32px;
            }

            img {
                width: 100%;
            }
        }

        .header-navigation {
            width: 320px;

            &.visible {
                @media (max-width: 720px) {
                    display: none;
                    visibility: hidden;
                }
            }

            &.open {
                top: 0px;
                opacity: 1;
                pointer-events: all;

                @media (max-width: 500px) {
                    top: 0px;
                }
            }

            @media (min-width: 1680px) {
                width: 340px;
            }

            @media (max-width: 720px) {
                width: 100vw;
                height: 100vh ;
                padding-top: 130px;
                position: absolute;
                background-color: $main-color;
                z-index: -5;
                pointer-events: none;
                top: -100%;
                left: 0;
                opacity: 0;
                transition: all 1s ease-in-out;
                @include mixins.flex(column, flex-start, center);
            }

            @media (max-width: 500px) {
                padding-top: 110px;
            }

            ul {
                list-style: none;
                gap: 32px;
                @include mixins.flex(row, flex-end, center);

                @media (max-width: 720px) {
                    gap: 24px;
                    @include mixins.flex(column, center, center);
                }

                .nav-items {
                    overflow: hidden;
                    position: relative;
                    color: $white;
                    cursor: pointer;
                    text-transform: uppercase;
                    @include mixins.font(Cinzel, 16px, normal, 561, 110%);

                    @media (min-width: 1680px) {
                        @include mixins.font(Cinzel, 18px, normal, 561, 110%);
                    }

                    @media (max-width: 720px) {
                        @include mixins.font(Cinzel, 40px, normal, 520, 110%);
                    }

                    &::before {
                        content: '';
                        width: 0px;
                        height: 1px;
                        background-color: $white;
                        position: absolute;
                        bottom: 0px;
                        right: 0px;
                        transition: all 0.3s ease-in-out;

                        @media (max-width: 720px) {
                            display: none;
                        }
                    }

                    &.hidden {
                        display: none;

                        @media (max-width: 720px) {
                            display: block;
                        }
                    }

                    &.open {
                        color: $black;
                    }
                }

                li:hover::before {
                    right: unset;
                    left: 0px;
                    width: 100%;
                }
            }
        }
    }
}

// Open/Close Icons

.open-icon{
    display: none;

    @media (max-width: 720px) {
        display: block;
    }
}

.close-icon{
    display: none;
}

.open-icon {
    &.open {
        display: none;
    }
}

.close-icon {
    &.open {
        display: block;
    }
}