@use '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.portfolio-nav {
    width: 100%;
    @include mixins.flex(row, center, center);

    .portfolio-nav-container {
        width: calc(100% - 160px);
        padding: 24px 0 48px 0;
        gap: 68px;
        @include mixins.flex(row, flex-start, center);

        @media (min-width: 1680px) {
            padding: 56px 0 80px 0;
            width: calc(100% - 200px);
        }

        @media (max-width: 1000px) {
            width: calc(100% - 80px);
            padding: 40px 0 48px 0;
            gap: unset;
            @include mixins.flex(row, space-between, center);
        }

        @media (max-width: 800px) {
            gap: 8px;
            @include mixins.flex(column, space-between, center);
        }

        @media (max-width: 500px) {
            padding: 48px 0 48px 0;
            width: calc(100% - 32px);
        }


        .portfolio-nav-item {
            overflow: hidden;
            position: relative;
            color: $grey;
            text-transform: uppercase;
            transition: all 0.3s ease-in-out;
            cursor: pointer;
            @include mixins.font(Cinzel, 48px, normal, 500, 140%);

            @media (min-width: 1680px) {
                @include mixins.font(Cinzel, 64px, normal, 500, 140%);
            }

            @media (max-width: 800px) {
                @include mixins.font(Cinzel, 40px, normal, 520, 110%);
            }

            &:hover {
                color: $black;
            }

            &.active {
                color: $black;
                overflow: visible;

                &::after {
                    content: url(../../assets/star-icon.svg);
                    position: absolute;
                    left: -28px;
                    top: 50%;
                    transform: translateY(-55%);
                }
            }

            &::before {
                content: '';
                width: 0px;
                height: 1px;
                background-color: $black;
                position: absolute;
                bottom: 0px;
                right: 0px;
                transition: all 0.3s ease-in-out;

                @media (max-width: 720px) {
                    display: none;
                }
            }
        }

        // .portfolio-nav-item:hover::before {
        //     right: unset;
        //     left: 0px;
        //     width: 100%;
        // }
    }
}