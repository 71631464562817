@use '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.form-section {
    color: $black;
    width: 100%;
    @include mixins.flex(row, center, center);

    .form-section-container {
        width: calc(100% - 290px);
        height: 910px;
        max-width: 1150px;
        position: relative;

        @media (min-width: 1680px) {
            max-width: 1442px;
            height: 1066px;
        }

        @media (max-width: 1350px) {
            height: 1580px;
        }

        @media (max-width: 1050px) {
            width: calc(100% - 80px);
            height: 1314px;
        }

        @media (max-width: 500px) {
            width: calc(100% - 32px);
            height: 804px;
        }

        .form-section-title-p1,
        .form-section-title-p2 {
            text-transform: uppercase;
            position: absolute;
            color: $white;
            mix-blend-mode: difference;
            z-index: 3;
            @include mixins.font(Cinzel, 88px, normal, 500, 110%);

            @media (min-width: 1680px) {
                @include mixins.font(Cinzel, 112px, normal, 520, 100%);
            }

            @media (max-width: 1050px) {
                @include mixins.font(Cinzel, 88px, normal, 520, 110%);
            }

            @media (max-width: 675px) {
                @include mixins.font(Cinzel, 40px, normal, 520, 110%);
            }
        }

        .form-section-title-p1 {
            margin-left: 24px;
            left: 0;
            top: 0;

            @media (max-width: 1350px) {
                left: unset;
                right: 0;
            }
        }

        .form-section-title-p2 {
            margin-right: 24px;
            right: 0;
            top: 94px;

            @media (min-width: 1680px) {
                top: 101px;
            }

            @media (max-width: 1350px) {
                width: 425px;
                text-align: end;
            }

            @media (max-width: 1050px) {
                margin-right: 0px;
            }

            @media (max-width: 675px) {
                width: 180px;
                top: 45px;
            }
        }

        .form-section-add-text {
            width: 160px;
            margin-right: 24px;
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            @include mixins.font(Lora, 18px, italic, 500, 120%);

            @media (min-width: 1680px) {
                width: 168px;
                @include mixins.font(Lora, 20px, italic, 500, 120%);
            }

            @media (max-width: 1350px) {
                top: 760px;
            }

            @media (max-width: 1050px) {
                top: 511px;
            }

            @media (max-width: 500px) {
                top: 197px;
                width: 104px;
                margin-right: 0px;
                @include mixins.font(Lora, 16px, italic, 400, 130%);
            }
        }

        .form-section-image {
            width: 487px;
            height: 665px;
            position: absolute;
            left: 0;
            top: 141px;
            margin-left: 28px;

            @media (min-width: 1680px) {
                width: 658px;
                height: 899px;
                top: 167px;
            }

            @media (max-width: 1050px) {
                width: 367px;
                height: 502px;
                top: 52px;
                margin-left: 0px;
            }

            @media (max-width: 500px) {
                width: 164px;
                height: 232px;
                top: 23px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .form-section-main-part {
            width: 487px;
            height: 670px;
            position: absolute;
            top: 240px;
            right: 0;

            @media (min-width: 1680px) {
                width: 655px;
                height: 718px;
                top: 348px;
            }

            @media (max-width: 1350px) {
                top: unset;
                bottom: 0;
                right: 50%;
                transform: translateX(50%);
            }

            @media (max-width: 1050px) {
                width: 496px;
                height: 655px;
            }

            @media (max-width: 675px) {
                width: 100%;
            }

            @media (max-width: 500px) {
                height: 491px;
            }
        }
    }
}