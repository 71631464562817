@use '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.thank-you-page {
    width: calc(100% - 80px);
    height: 70vh;
    padding: 0 40px;
    margin-top: 110px;
    gap: 40px;
    @include mixins.flex(column, center, center);

    @media (max-width: 500px) {
        height: 80vh;
        margin-top: 60px;
    }

    h1 {
        color: $black;
        text-transform: uppercase;
        text-align: center;
        @include mixins.font(Cinzel, 35px, normal, 500, 100%);

        @media (max-width: 500px) {
            @include mixins.font(Cinzel, 25px, normal, 500, 100%);
        }
    }

    h2 {
        color: $grey;
        text-transform: uppercase;
        text-align: center;
        @include mixins.font(Cinzel, 20px, normal, 500, 100%);

        @media (max-width: 500px) {
            @include mixins.font(Cinzel, 15px, normal, 500, 100%);
        }
    }

    button {
        width: 134px;
        height: 47px;
        background-color: $transparent;
        color: $black;
        border-radius: 12px 0px;
        border: 1px solid $black;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        @include mixins.font(Cinzel, 16px, normal, 561, 120%);

        &:hover {
            background-color: $black;
            color: $main-color;
        }

        @media (min-width: 1680px) {
            width: 143px;
            @include mixins.font(Cinzel, 18px, normal, 561, 120%);
        }
    }
}