@use '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';


.footer-carrousel-box {
    width: 1280px;
    @include mixins.flex(row, flex-start, flex-start);
    animation: 25s slide infinite linear;

    @media (min-width: 1680px) {
        width: 1720px;
    }

    @media (max-width: 1250px) {
        width: 1158px;
    }

    @media (max-width: 500px) {
        width: 706px;
    }

    .footer-image {
        margin: 0 40px;

        @media (min-width: 1680px) {
            margin: 0 50px;
        }

        @media (max-width: 1250px) {
            margin: 0 20px;
        }

        @media (max-width: 500px) {
            margin: 0 8px;
        }

        &.first {
            width: 260px;

            @media (min-width: 1680px) {
                width: 355px;
            }

            @media (max-width: 1250px) {
                width: 256px;
            }

            @media (max-width: 500px) {
                width: 164px;
            }
        }

        &.second {
            width: 147px;
            padding-top: 32px;
            
            @media (min-width: 1680px) {
                width: 203px;
                padding-top: 41px;
            }

            @media (max-width: 1250px) {
                width: 147px;
                padding-top: 32px;
            }

            @media (max-width: 500px) {
                width: 94px;
                padding-top: 32px;
            }
        }

        &.third {
            width: 260px;

            @media (min-width: 1680px) {
                width: 355px;
            }

            @media (max-width: 1250px) {
                width: 260px;
            }

            @media (max-width: 500px) {
                width: 164px;
            }
        }

        &.fourth {
            width: 373px;
            padding-top: 87px;

            @media (min-width: 1680px) {
                width: 507px;
                padding-top: 97px;
            }

            @media (max-width: 1250px) {
                width: 373px;
                padding-top: 87px;
            }

            @media (max-width: 500px) {
                width: 236px;
                padding-top: 80px;
            }
        }


        img {
            width: 100%;
        }
        
    }
}

@keyframes slide {
    from {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    to {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}