@use '../../styles/mixins.scss';
@import '../../styles/variables.scss';


.gallery-section {
    width: 100%;
    @include mixins.flex(row, center, center);

    .gallery-section-container {
        width: calc(100% - 160px);
        // max-width: 1280px;
        padding-bottom: 50px;
        flex-wrap: wrap;
        row-gap: 96px;
        position: relative;
        margin-bottom: 296px;
        @include mixins.flex(row, space-between, flex-end);

        @media (min-width: 1680px) {
            width: calc(100% - 200px);
            max-width: 1720px;
            row-gap: 120px;
            margin-bottom: 360px;
        }

        @media (max-width: 1000px) {
            width: calc(100% - 80px);
            padding-bottom: 130px;
            row-gap: 20px;
            margin-bottom: 184px;
        }

        @media (max-width: 600px) {
            row-gap: 15px;
        }

        @media (max-width: 500px) {
            width: calc(100% - 32px);
            padding-bottom: 90px;
            margin-bottom: 128px;
        }

        .gallery-image {
            width: 373px;
            height: 552px;

            @media (min-width: 1650px) {
                width: 30%;
                height: 753px;
            }

            @media (max-width: 1350px) {
                width: 31%; 
            }

            @media (max-width: 1000px) {
                width: calc(50% - 10px);
                height: 543px;
            }

            @media (max-width: 600px) {
                width: calc(50% - 7.5px);
                height: 340px;
            }

            @media (max-width: 450px) {
                height: 290px;
            }

            @media (max-width: 400px) {
                height: 240px;
            }

            &:nth-child(3n+2) {
                margin-bottom: 84px;

                @media (min-width: 1680px) {
                    margin-bottom: 104px;
                }

                @media (max-width: 1000px) {
                    margin-bottom: unset;
                }
            }
        
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

            }
        }

        .gallery-section-button {
            width: 134px;
            height: 47px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: $transparent;
            color: $black;
            border-radius: 12px 0px;
            border: 1px solid $black;
            text-transform: uppercase;
            transition: all 0.3s ease-in-out;
            cursor: pointer;
            @include mixins.font(Cinzel, 16px, normal, 561, 120%);

            &:hover {
                background-color: $black;
                color: $main-color;
            }

            @media (min-width: 1680px) {
                width: 143px;
                @include mixins.font(Cinzel, 18px, normal, 561, 120%);
            }
        }
    }
}

