@use '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.portfolio-item {
    width: 100%;
    gap: 40px;
    @include mixins.flex(column, flex-start, flex-start);

    @media (max-width: 1000px) {
        gap: 24px;
        @include mixins.flex(column, flex-start, center);
    }

    @media (max-width: 500px) {
        gap: 16px;
    }


    .portfolio-item-title {
        width: 100%;
        gap: 8px;
        position: relative;
        z-index: 3;
        @include mixins.flex(row, flex-start, flex-end);

        @media (max-width: 800px) {
            width: calc(100% - 40px);
            margin-left: 40px;
        }

        @media (max-width: 500px) {
            width: calc(100% - 16px);
            margin-left: 16px;
        }

        h3 {
            color: $grey;
            white-space: nowrap;
            text-transform: uppercase;
            @include mixins.font(Cinzel, 20px, normal, 500, 100%);

            @media (min-width: 1680px) {
                @include mixins.font(Cinzel, 28px, normal, 500, 100%);
            }

            @media (max-width: 500px) {
                @include mixins.font(Cinzel, 18px, normal, 500, 110%);
            }
        }

        div {
            width: 100%;
            height: 1px;
            margin-bottom: 4px;
            background-color: $line-color;

            @media (min-width: 1680px) {
                margin-bottom: 6px;
            }
        }
    }

    .portfolio-item-preview {
        width: 100%;
       
        // cursor: pointer;
        @include mixins.flex(row, flex-start, flex-start);

        // @media (min-width: 1680px) {
        // }

        // @media (max-width: 1000px) {
        //     padding-bottom: 71px;
        // }

        // @media (max-width: 800px) {
        //     gap: 0;
        // }

        // @media (max-width: 500px) {
        //     padding-bottom: 63px;
        // }

        .portfolio-item-preview-box {
            width: 100%;
            gap: 40px;
            @include mixins.flex(row, flex-start, flex-start);

            @media (min-width: 1680px) {
                gap: 48px;
            }

            @media (max-width: 1000px) {
                gap: 20px;
            }

            @media (max-width: 800px) {
                width: 799px;
                margin: 0 10px;
                gap: 0;
                animation: 25s slide infinite linear;
            }

            @media (max-width: 500px) {
                width: 791px;
                margin: 0 8px;
            }
        }

        .portfolio-item-image {
            height: 552px;
            flex: 1;
            max-width: 100%;

            @media (min-width: 1680px) {
                height: 746px;
            }

            @media (max-width: 1200px) {
                height: 450px;
            }

            @media (max-width: 1000px) {
                height: 328px;
            }

            @media (max-width: 800px) {
                flex: none;
                width: 253px;
                height: 352px;
                margin: 0 10px;
                max-width: unset;
            }

            @media (max-width: 500px) {
                margin: 0 8px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .portfolio-item-button {
            width: 180px;
            height: 180px;
            border-radius: 1000px;
            border: 3px solid $beige-stroke;
            background: $beige;
            position: absolute;
            // left: 0;
            // top: 0;
            // left: 50%;
            transform: scale(0);
            transform-origin: center;
            text-transform: uppercase;
            opacity: 0;
            transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;            
            cursor: pointer;
            @include mixins.font(Cinzel, 16px, normal, 561, 120%);

            @media (min-width: 1680px) {
                @include mixins.font(Cinzel, 18px, normal, 561, 120%);
            }

            @media (max-width: 1000px) {
                display: none;
            }
    
            span {
                color: $brown;
                mix-blend-mode: difference;
            }
        }
    }

    .portfolio-item-preview:hover .portfolio-item-button {
        transform: scale(1);
        opacity: 1;
    }

    a {
        @media (max-width: 500px) {
            width: calc(100% - 32px);
        }
    }

    .portfolio-item-button-mobile {
        display: none;
        width: 134px;
        height: 47px;
        background-color: $transparent;
        color: $black;
        border-radius: 12px 0px;
        border: 1px solid $black;
        transition: all 0.3s ease-in-out;
        @include mixins.font(Cinzel, 16px, normal, 561, 120%);

        &:hover {
            background-color: $black;
            color: $main-color;
        }

        @media (max-width: 1000px) {
            display: inline-block;
        }

        @media (max-width: 500px) {
            width: 100%;
        }
    }
}

// Slide Animation

@keyframes slide {
    from {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    to {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}