@use '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.gallery-page {
    width: 100%;
    margin-top: 110px;
    @include mixins.flex(column, center, center);

    @media (max-width: 500px) {
        margin-top: 60px;
    }
    
    .gallery-page-container {
        width: calc(100% - 160px);
        // max-width: 1280px;
        gap: 40px;
        @include mixins.flex(column, flex-start, center);

        @media (min-width: 1680px) {
            width: calc(100% - 200px);
            @include mixins.flex(column, flex-start, center);
        }

        @media (max-width: 1000px) {
            width: calc(100% - 80px);
        }

        @media (max-width: 700px) {
            gap: 58px;
        }

        @media (max-width: 500px) {
            width: calc(100% - 32px);
        }

        .gallery-page-title {
            width: 100%;
            padding: 40px 0 16px 0;
            position: relative;
            @include mixins.flex(row, space-between, center);

            @media (min-width: 1680px) {
                padding: 40px 0 10px 0;
            }

            @media (max-width: 900px) {
                padding: 33px 0 10px 0;
            }

            @media (max-width: 700px) {
                padding: 16px 0 16px 0;
            }

            .gallery-page-title-part {
                gap: 16px;
                @include mixins.flex(row, space-between, center);

                &.first {

                    .gallery-page-back-btn {
                        position: relative;
                        text-transform: uppercase;
                        gap: 8px;
                        cursor: pointer;
                        @include mixins.font(Cinzel, 20px, normal, 500, 110%);
                        @include mixins.flex(row, space-between, center);

                        @media (min-width: 1680px) {
                            @include mixins.font(Cinzel, 28px, normal, 500, 140%);
                        }

                        @media (max-width: 700px) {
                            @include mixins.font(Cinzel, 18px, normal, 500, 110%);
                        }

                        &::before {
                            content: '';
                            width: 0px;
                            height: 1px;
                            background-color: $black;
                            position: absolute;
                            bottom: 0px;
                            right: 0px;
                            transition: all 0.3s ease-in-out;
                        }

                        img,
                        span {
                            pointer-events: none;
                        }
                    }

                    .gallery-page-back-btn:hover::before {
                        right: unset;
                        left: 0px;
                        width: 100%;
                    }

                    h2 {
                        text-transform: uppercase;
                        @include mixins.font(Cinzel, 20px, normal, 500, 110%);

                        @media (min-width: 1680px) {
                            @include mixins.font(Cinzel, 28px, normal, 500, 140%);
                        }

                        @media (max-width: 700px) {
                            @include mixins.font(Cinzel, 18px, normal, 500, 110%);
                        }
                    }
                }

                &.second {

                    @media (max-width: 700px) {
                        position: absolute;
                        right: 0;
                        bottom: -40px;
                    }

                    span {
                        color: $grey;
                        text-transform: uppercase;
                        @include mixins.font(Cinzel, 16px, normal, 561, 120%);

                        @media (min-width: 1680px) {
                            @include mixins.font(Cinzel, 18px, normal, 561, 120%);
                        }

                        @media (max-width: 700px) {
                            @include mixins.font(Cinzel, 14px, normal, 520, 130%);
                        }
                    }
                }
            }

            .gallery-page-title-border {
                width: 100%;
                height: 1px;
                background-color: $line-color;
                position: absolute;
                bottom: 0;
            }
        }

        .gallery-page-images-box {
            width: 100%;
            // flex-wrap: wrap;
            gap: 12px;
            position: relative;
            // max-width: 1280px;
            @include mixins.flex(column, flex-start, flex-start);

            @media (min-width: 1680px) {
                
            }

            // @media (max-width: 900px) {
            //     gap: 20px;
            // }

            // @media (max-width: 500px) {
            //     gap: 16px;
            // }

            .gallery-page-images-box-preloader {
                width: 100vw;
                height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, calc(-50% - 2px));
                background-color: $main-color;
                transition: all 1s ease-in-out;

                &.hidden {
                    pointer-events: none;
                    opacity: 0;
                }
            }

            .gallery-row {
                gap: 12px;
                @include mixins.flex(row, flex-start, flex-start);
            }

            .gallery-page-images-item {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .horizontal {
                // width: 400px;
                height: 100%;
            }

            .vertical {
                height: 100%;
                // width: 200px;
                // height: 400px;
            }

            .gallery-column {
                flex: 1;
                max-width: calc(33.33% - 20px);
                gap: 40px;
                @include mixins.flex(column, flex-start, flex-start);

                @media (min-width: 1680px) {
                    max-width: calc(33.33% - 24px);
                    gap: 48px;
                }

                @media (max-width: 900px) {
                    max-width: calc(50% - 10px);
                    gap: 20px;
                }

                @media (max-width: 500px) {
                    max-width: calc(50% - 8px);
                    gap: 16px;
                }
            }
        }
    }

}