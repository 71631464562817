@use '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.couples-page {
    width: 100%;
    margin-top: 110px;
    @include mixins.flex(column, center, center);

    @media (max-width: 500px) {
        margin-top: 60px;
    }
    
    .couples-section {
        width: calc(100% - 160px);
        gap: 64px;
        position: relative;
        @include mixins.flex(column, flex-start, flex-start);

        @media (min-width: 1680px) {
            width: calc(100% - 200px);
            gap: 80px;
        }

        @media (max-width: 1000px) {
            width: calc(100% - 80px);
            gap: 40px;
        }

        @media (max-width: 800px) {
            width: 100%;
            overflow: hidden;
        }

        @media (max-width: 500px) {
            gap: 48px;
        }
    }
}